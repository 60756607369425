import Rails from "@rails/ujs";
import jquery from "jquery";

import "selectize";
import "selectize/dist/css/selectize.css";

import ahoy from "ahoy.js";

declare global {
  interface Window {
    _rails_loaded: boolean;
    Rails: typeof Rails;
    $: typeof jquery;
    jQuery: typeof jquery;
  }
}

window.jQuery = jquery;
window.$ = jquery;
// @ts-ignore
window.ahoy = ahoy;
ahoy.configure({ cookies: false });

if (!window._rails_loaded) {
  Rails.start();
}

$(() => {
  // @ts-expect-error
  $("select.selectize-field").selectize({ sortField: "asc" });
});
